var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row no-gutters flex-grow-1 flex-shrink-1"},[_c('div',{staticClass:"spacer bg-dark"}),_c('div',{staticClass:"col"},[_c('span',{staticClass:"ml-2 text-primary"},[_vm._v(" "+_vm._s(_vm.multiple ? "Escolha até três opções" : "Escolha uma opção")+" ")])]),_c('div',{staticClass:"spacer bg-dark"})]),_vm._l((_vm.group),function(checkbox,index){return _c('div',{key:((checkbox.id) + "-" + index),class:[
      'row no-gutters btn-wrapper',
      index == 0 ? 'first' : index == _vm.group.length - 1 ? 'last' : '' ]},[_c('div',{class:[
        'spacer',
        _vm.value[index] ? 'default' : index % 2 == 0 ? 'space-2' : 'space-1' ]}),_c('div',{staticClass:"col"},[_c('button',{class:['btn', 'btn-block', _vm.value[index] ? 'selected' : 'default'],on:{"click":function($event){return _vm.toggle(index)}}},[_c('span',{class:[
            'choice',
            _vm.value[index] ? 'default' : index % 2 == 0 ? 'left' : 'right' ]},[_vm._v(" "+_vm._s(checkbox.content)+" ")])])]),_c('div',{class:[
        'spacer',
        _vm.value[index] ? 'default' : index % 2 == 0 ? 'space-1' : 'space-2' ]})])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }