import { db, auth } from "@/scripts/firebaseConfig.js";
import store from "../store/index";

export const manifest = require("../assets/data/manifest/data")

export var userData = {};
export let data = {};
export let valid = {};
export let log = [];

export function signInAnonymous(successCallback, errorCallback) {
    if (store.getters.user.loggedIn) {
        successCallback();
        return
    }
    auth.signInAnonymously()
        .then(function () {
            successCallback();
        }).catch(function (error) {
            errorCallback(error);
        });
}

export async function sendData(successCallback, errorCallback) {

    const trackRef = db.collection('tracks').doc();
    var trackId = trackRef.id;
    var sendData = {}

    sendData["log"] = log;
    sendData["data"] = data;
    sendData["createdOn"] = new Date();
    sendData["user"] = userData;

    trackRef.set(sendData).then(function () {
        successCallback(trackId);
    }).catch(function (error) {
        errorCallback(error);
    });
}

export function getWorldStats(successCallback, errorCallback) {
    db.collection("stats").get()
        .then(function (querySnapshot) {
            var data = {};
            querySnapshot.forEach(function (doc) {
                data[doc.id] = doc.data();
            })
            successCallback(data)
        }).catch(function (error) {
            errorCallback(error);
        });
}

export function loadTrack(successCallback) {
    data = {};
    valid = {};
    log = [];

    const requireContext = require.context("../assets/data/nodes", true, /\.json$/);
    let result = { entryPoint: null, track: {} };

    requireContext.keys().forEach((key) => {
        const obj = requireContext(key);
        const id = key.slice(2, key.length - 5);
        obj["id"] = id;
        result.track[id] = obj;
        if (obj["entryPoint"]) result.entryPoint = id;

        valid[id] = {}
    });
    if (!result.entryPoint)
        result.entryPoint = result.track[Object.keys(result.track)[0]];

    successCallback(result);
}

export function getTrackData(uid, successCallback, errorCallback) {
    const ref = db.collection('tracks').doc(uid);
    ref.get()
        .then(function (doc) {
            var trackData = formatManifest(doc.data());
            successCallback(trackData);
        })
        .catch(function (error) {
            errorCallback(error);
        });
}

// import { images } from "../scripts/images"

function formatManifest(doc) {

    var result = {
        title: doc.user.name
            ? "Manifesto de " + doc.user.name
            : "Meu manifesto",
        name: doc.user.name,
        date: doc.createdOn,
        manifest: [],
    };

    var keys = Object.keys(doc.data).sort(function (a, b) {
        if (a < b) { return -1; }
        if (a > b) { return 1; }
        return 0;
    });

    var paragraphs = [];
    for (var index in keys) {
        var nodeId = keys[index];
        var node = doc.data[nodeId];
        var header = manifest[nodeId].header.result;
        var choiceKeys = []
        Object.keys(node).forEach(key => {
            if (node[key])
                choiceKeys.push(key);
        });
        var choices = []
        var choiceImg = null;
        for (var choiceIndex in choiceKeys) {
            var choiceKey = choiceKeys[choiceIndex];
            choices.push(manifest[nodeId].choice[choiceKey].result);
            choiceImg = manifest[nodeId].choice[choiceKey].img
        }
        paragraphs.push(formatStrings({ header: header, choices: choices, img: choiceImg }))
    }

    var paragraphPerPage = Math.floor((paragraphs.length - 1) / 4);
    var plus = (paragraphs.length - 1) % 4 == 0 ? 0 : 1;
    for (var screenIndex = 0; screenIndex < 4; screenIndex++) {
        var img = null;
        var screenParagraph = "";
        for (var paragraphIndex = screenIndex * paragraphPerPage; paragraphIndex < (screenIndex * paragraphPerPage) + paragraphPerPage; paragraphIndex++) {
            if (paragraphIndex != screenIndex * paragraphPerPage)
                screenParagraph += " ";
            screenParagraph += (paragraphs[paragraphIndex].header != 0 ? paragraphs[paragraphIndex].header + " " : "") + paragraphs[paragraphIndex].choices.join(" ")
            if (paragraphs[paragraphIndex].img) img = paragraphs[paragraphIndex].img

        }
        var title = ""
        for (var x = 0; x < 3; x++) {
            var spaceIndex = screenParagraph.indexOf(" ");
            title += (title.length > 0 ? " " : "") + screenParagraph.substr(0, spaceIndex);
            screenParagraph = screenParagraph.substr(spaceIndex, screenParagraph.length - 1).trim()
        }
        result.manifest.push({
            message: "<div class='border-left-container'><div class='story-title'>" + title + "...</div><div class='story-body'>..." + screenParagraph + "</div></div>", image: img ? img : storyImages[screenIndex]
        })
    }
    if (plus > 0) {
        result.manifest.push({ message: "<div class='border-left-container'><div class='story-body'>" + paragraphs[paragraphs.length - 1].header + "</div><div class='story-title'>" + paragraphs[paragraphs.length - 1].choices.join(" ") + "</div></div>", image: storyImages[4] })
    }
    var finalKey = 'a'
    Object.keys(doc.data['16']).forEach(key => {
        if (node[key])
            finalKey = key;
    });
    result.manifest.push({
        message: "<div class='border-left-container'><div class='story-title'>Obrigado por participar!</div><div class='story-body'>" + finalMessage[finalKey].text + "</div></div><div class='d-flex justify-content-center mt-2'><a href='" + finalMessage["a"].link + "' target='_blank' class='btn btn-quem-seremos t6'>Clique aqui</a></div>", image: "00"
    })

    return result;
}

var storyImages = ["03_01", "05_01", "09_01", "12_01", "16_01"];

var finalMessage = {
    a: { text: "Se você está nessa busca pelo que realmente te importa, temos algumas sugestões para dar esse passo.<br />Clique, confira e compartilhe seu manifesto com quem é importante para você.", link: "https://quemseremos.com.br/#autoconhecimento" },
    b: { text: "Se você quer descobrir como como contribuir para o mundo, temos algumas sugestões para dar esse passo.<br />Clique, confira e compartilhe seu manifesto com quem é importante para você.", link: "https://quemseremos.com.br/#doacoes" },
    c: { text: "Se você está nessa busca para aproveitar melhor o seu tempo, temos algumas sugestões para dar esse passo.<br />Clique, confira e compartilhe seu manifesto com quem é importante para você.", link: "https://quemseremos.com.br/#autoconhecimento" },
    d: { text: "Se você descobriu que quer útil às pessoasa, temos algumas sugestões para dar esse passo.<br />Clique, confira e compartilhe seu manifesto com quem é importante para você.", link: "https://quemseremos.com.br/#voluntariado" },
    e: { text: "Se você está nessa busca pela vida plena, temos algumas sugestões para dar esse passo.<br />Clique, confira e compartilhe seu manifesto com quem é importante para você.", link: "https://quemseremos.com.br/#autoconhecimento" }
}

function formatStrings(paragraph) {

    var capitalizePunctuations = ".:;!?";

    var header = capitalize(paragraph.header.trim());
    var choices = []
    for (var choiceIndex in paragraph.choices) {
        var choice = paragraph.choices[choiceIndex]
        var compareStr = choiceIndex == 0 ? header : paragraph.choices[choiceIndex - 1];
        var formatedChoice = "";
        if (capitalizePunctuations.includes(compareStr[compareStr.length - 1]) || compareStr.length == 0)
            formatedChoice = capitalize(choice);
        else
            formatedChoice = firstLowercase(choice);
        if (!capitalizePunctuations.includes(formatedChoice[formatedChoice.length - 1])) {
            if (choiceIndex == paragraph.choices.length - 1)
                formatedChoice += ".";
            else
                formatedChoice += ",";
        }
        choices.push(formatedChoice);
    }
    return { header: header, choices: choices, img: paragraph.img }
}

function capitalize(s) {
    if (typeof s !== 'string') return ''
    s = s.trim();
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function firstLowercase(s) {
    if (typeof s !== 'string') return ''
    s = s.trim();
    return s.charAt(0).toLowerCase() + s.slice(1)
}