<template>
  <div>
    <div class="row no-gutters flex-grow-1 flex-shrink-1">
      <div class="spacer bg-dark"></div>
      <div class="col">
        <span class="ml-2 text-primary">
          {{ multiple ? "Escolha até três opções" : "Escolha uma opção" }}
        </span>
      </div>
      <div class="spacer bg-dark"></div>
    </div>
    <div
      v-for="(checkbox, index) in group"
      :key="`${checkbox.id}-${index}`"
      :class="[
        'row no-gutters btn-wrapper',
        index == 0 ? 'first' : index == group.length - 1 ? 'last' : '',
      ]"
    >
      <div
        :class="[
          'spacer',
          value[index] ? 'default' : index % 2 == 0 ? 'space-2' : 'space-1',
        ]"
      ></div>
      <div class="col">
        <button
          :class="['btn', 'btn-block', value[index] ? 'selected' : 'default']"
          @click="toggle(index)"
        >
          <span
            :class="[
              'choice',
              value[index] ? 'default' : index % 2 == 0 ? 'left' : 'right',
            ]"
          >
            {{ checkbox.content }}
          </span>
        </button>
      </div>
      <div
        :class="[
          'spacer',
          value[index] ? 'default' : index % 2 == 0 ? 'space-1' : 'space-2',
        ]"
      ></div>
    </div>
  </div>
</template>
<script>
import { data, valid } from "../scripts/data";
export default {
  name: "check-group",
  props: {
    id: String,
    nodeId: String,
    multiple: Boolean,
    hasInvalid: Boolean,
    isActive: Boolean,
    manifest: Object,
  },
  data: () => ({
    group: [],
    value: [],
    valid: false,
    invalidMessage: "",
    indexStack: [],
  }),
  mounted() {
    this.group = [];
    var choices = this.manifest[this.id].choice;

    Object.keys(choices).forEach((key) => {
      this.group.push({ id: key, content: choices[key].title });
    });
    for (let i = 0; i < this.group.length; i++) {
      this.value[i] = false;
    }
    this.validate();
    this.emitValue();
  },
  methods: {
    toggle(index) {
      if (!this.multiple) {
        for (var i = 0; i < this.value.length; i++) {
          this.value[i] = false;
        }
        this.indexStack = [];
      }

      this.value[index] = !this.value[index];

      if (this.value[index]) this.indexStack.push(index);
      else this.indexStack.splice(this.indexStack.indexOf(index), 1);

      if (this.multiple && this.indexStack.length > 3) {
        this.value[this.indexStack[0]] = false;
        this.indexStack.splice(0, 1);
      }

      this.$forceUpdate();
      this.validate();
      this.emitValue();
    },
    validate() {
      let valid = { value: true, invalidMessage: "" };
      let hasChecked = false;
      this.value.forEach((element) => {
        if (element) hasChecked = true;
      });
      if (!hasChecked)
        valid = {
          value: false,
          invalidMessage: this.multiple
            ? "Escolha até três opções"
            : "Escolha uma opção",
        };
      this.valid = valid.value;
      this.invalidMessage = valid.invalidMessage;
    },
    emitValue() {
      valid[this.nodeId][this.id] = this.valid;
      var value = {};
      for (let i = 0; i < this.group.length; i++) {
        value[this.group[i].id] = this.value[i];
      }
      data[this.id] = value;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";

.btn-wrapper {
  border-top: 0.4rem solid $dark;
  border-bottom: 0.4rem solid $dark;
}

.btn-wrapper.first {
  border-top: 0.8rem solid $dark;
  border-bottom: 0.4rem solid $dark;
}

.btn-wrapper.last {
  border-top: 0.4rem solid $dark;
  border-bottom: 0.8rem solid $dark;
}

.btn {
  transition: box-shadow 1s ease;
}

.btn.selected {
  background: transparent;
  border: 0;
  color: $light;
  box-shadow: 0 0 0 0.2rem $primary inset;
}

.btn.selected:hover {
  background: rgba($dark, 0.1);
  border: 0;
  color: $light;
}

.btn.selected:active,
.btn.selected:focus {
  background: transparent;
  border-color: $primary;
  border: 0;
  color: $light;
  // box-shadow: 0 0 0 0.2rem $primary inset;
}

.btn.default {
  background: transparent;
  border-color: $light;
  border: 0;
  color: $light;
}

.btn.default:hover {
  background: rgba($dark, 0.1);
  border: 0;
  color: $light;
}

.btn.default:active,
.btn.default:focus {
  background: transparent;
  border-color: $light;
  border: 0;
  box-shadow: none;
  color: $light;
}
.spacer {
  transition: max-width 1s ease, flex 1s ease;
  will-change: max-width, flex;
}
.spacer.default {
  max-width: $spacer-width;
  flex: 0 0 $spacer-width;
}
.spacer.space-1 {
  max-width: $spacer-width * 0.8;
  flex: 0 0 $spacer-width * 0.8;
}
.spacer.space-2 {
  max-width: $spacer-width * 1.2;
  flex: 0 0 $spacer-width * 1.2;
}
.choice {
  transition: padding 1s ease;
  will-change: padding;
  padding-right: $spacer-width * 0.225;
  padding-left: $spacer-width * 0.225;
}
.choice.right {
  // margin-left: $spacer-width * 0.225;
  // margin-right: -$spacer-width * 0.225;

  padding-right: 0;
  padding-left: $spacer-width * 0.45;
}
.choice.left {
  // margin-left: -$spacer-width * 0.225;
  // margin-right: $spacer-width * 0.225;

  padding-right: $spacer-width * 0.45;
  padding-left: 0;
}
</style>