<template>
  <background image="00" class="full-container border-top-container">
    <logo />
    <div class="border-left-container">
      <div class="title mb-2">obrigado!</div>
      <div class="body">
        Acesse seu manifesto pessoal da pandemia na próxima tela ou o receba por
        email.
      </div>
    </div>
    <div class="container">
      <div class="mt-2 row">
        <div class="col-12 mt-2">
          <b-form-input
            type="text"
            placeholder="nome (opcional)"
            v-model="name"
          ></b-form-input>
        </div>
        <div class="col-12 mt-2">
          <b-form-input
            type="email"
            placeholder="email (opcional)"
            v-model="email"
          ></b-form-input>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <a href="#" v-b-modal.modal-methodology>
        METODOLOGIA E POLÍTICA DE PRIVACIDADE
      </a>
    </div>
    <div class="d-flex justify-content-center">
      <b-form-checkbox v-model="accept">
        Eu aceito os termos de uso.
      </b-form-checkbox>
    </div>
    <div class="d-flex justify-content-center mt-2">
      <b-button variant="quem-seremos" @click="end">
        <span class="btn-text">próximo</span>
      </b-button>
    </div>
    <b-modal
      id="modal-methodology"
      scrollable
      centered
      ok-only
      hide-header
      button-size="sm"
      header-text-variant="dark"
      body-text-variant="dark"
    >
      <div class="mb-2"><b>METODOLOGIA E POLÍTICA DE PRIVACIDADE</b></div>
      <div class="mb-2"><b>METODOLOGIA</b></div>
      <div class="mb-2">
        Criada e realizada voluntariamente pelos fundadores dos escritórios
        Studio Ideias, [EM BRANCO ] e Pepita, a plataforma Quem Seremos? é um
        convite à busca de respostas individuais e coletivas a todos os
        sentimentos que emergiram com força na pandemia.
      </div>
      <div class="mb-2">
        Quem Seremos? nasceu de uma pesquisa qualitativa. Entre o final de Abril
        e o início de Julho de 2020, ouvimos individualmente, 40 brasileiros
        plurais, homens e mulheres, de 18 a 70 anos, nas cinco regiões do país,
        da capital paulistana ao sertão do Ceará através de uma plataforma
        digital e entrevistas virtuais. Também voluntários, os entrevistados
        responderam semanalmente às mesmas questões, dando um pulso à
        perplexidade, assim como aos muitos questionamentos que o vazio das ruas
        levaram para dentro das casas, corações e cabeças.
      </div>
      <div class="mb-2">
        Esse game foi construído a partir das respostas encontradas nessa
        investigação, que serviram de inpiração para as perguntas e alternativas
        que você encontra no jogo.
      </div>
      <div class="mb-2">
        Além disso, contamos com a mentoria, também voluntária, de Reginaldo
        Lima, empreendedor e líder comunitário do Morro do Alemão, no Rio de
        Janeiro.
      </div>
      <div class="mb-4">
        Você pode conhecer melhor a plataforma Quem Seremos? e os resultados da
        pesquisa, que também contou com uma etapa quantitativa, realizada em
        Dezembro de 2020 aqui:
        <a class="text-dark" href="https://quemseremos.com.br" target="_blank"
          >www.quemseremos.com.br</a
        >
      </div>
      <div><b>POLÍTICA DE PRIVACIDADE</b></div>
      <div class="mb-2">
        A Folha garante a privacidade de seus leitores e das pessoas que
        interagem com as suas páginas.
      </div>
      <div class="mb-2">
        As respostas da experiência “Quem Seremos” serão armazenadas pela
        Neptune Films Ltda. (Pepita), sociedade empresária inscrita no CNPJ/ME
        sob nº 24.449.228/0001-21, mas só serão identificadas ao seu nome e
        e-mail se você consentir com isso.
      </div>
      <div class="mb-2">
        Esses mesmos dados da experiência e de sua interação com a página,
        incluindo dados técnicos de acesso, podem também ser compilados, sem
        identificação de quem os forneceu, para produção de estatísticas
        internas da Folha e outras atividades previstas em nossa política de
        privacidade. Além disso, os dados da experiência, também sem
        identificação, poderão ser utilizados para estatísticas a serem
        publicadas por empresas com as quais os resultados anônimos forem
        compartilhados.
      </div>
      <div class="mb-2">
        O seu nome e e-mail somente serão armazenados pela Pepita se você
        consentir, no final da experiência, que a Pepita, patrocinadora do
        projeto, utilize-os para envio de comunicações da empresa e materiais de
        divulgação de outras iniciativas similares à “Quem Seremos”.
      </div>
      <div class="mb-2">
        Ao consentir em enviar o seu nome e e-mail para a Pepita, esteja ciente
        de que:
      </div>
      <ul>
        <li class="mb-2">
          Seus dados somente serão utilizados para as finalidades descritas
          acima e não serão vendidos ou compartilhados com ninguém, exceto com
          os colaboradores da Pepita (somente os que necessitarem acessá-los e
          se houver justificativa para isso) e os serviços de hospedagem de base
          de dados contratados pela empresa (cujos servidores estão hospedados
          nos EUA);
        </li>
        <li class="mb-2">
          Você poderá revogar o seu consentimento em relação à utilização dos
          seus dados para uma ou mais finalidades descritas acima enviando um
          e-mail para
          <a
            class="text-dark"
            href="mailto:contato@quemseremos.com.br"
            target="_blank"
          >
            contato@quemseremos.com.br </a
          >; e
        </li>
        <li class="mb-2">
          Os seus dados serão apagados pela Pepita após o cumprimento das
          finalidades aqui descritas ou a revogação do seu consentimento, o que
          ocorrer primeiro.
        </li>
      </ul>
      <div class="mb-2">
        Você pode tirar quaisquer dúvidas sobre como os seus dados estão sendo
        tratados e protegidos enviando um e-mail para
        <a
          class="text-dark"
          href="mailto:contato@quemseremos.com.br"
          target="_blank"
        >
          contato@quemseremos.com.br </a
        >.
      </div>
    </b-modal>
  </background>
</template>
<script>
import { userData } from "../scripts/data";
import Background from "./Background.vue";
export default {
  components: { Background },
  data: () => ({ name: "", email: "", accept: false }),
  methods: {
    end() {
      if (!this.accept && (this.name || this.email)) {
        this.infoModal(
          "Caso tenha preenchido seu nome e/ou email você precisa aceitar os termos de METODOLOGIA E POLÍTICA DE PRIVACIDADE para poder avançar."
        );
        return;
      }
      userData["name"] = this.name;
      userData["email"] = this.email;
      this.$emit("change-state");
    },
    infoModal(message) {
      this.$bvModal.msgBoxOk(message, {
        buttonSize: "sm",
        bodyClass: "text-dark font-weight-bold px-3",
        footerClass: "p-2 border-top-0",
        centered: true,
        hideHeader: true,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
a {
  color: $light;
}
a:hover,
a:focus,
a:active {
  color: $primary;
}
.body {
  font-size: 1.8rem;
}
@media only screen and (max-width: 768px) {
  .body {
    font-size: 0.9rem;
  }
}
.title {
  font-size: 5rem;
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
}
.btn-text {
  font-size: 2rem;
}
@media only screen and (max-width: 768px) {
  .btn-text {
    font-size: 1.5rem;
  }
}
.border-left-container {
  border-left: 0.2rem solid $primary;
  padding: 1rem;
  width: 70%;
}

@media only screen and (max-width: 768px) {
  .border-left-container {
    width: 100%;
    padding: 0.8rem;
  }
}
</style>