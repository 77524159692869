<template>
  <loader
    :loading="loading"
    :error="error"
    errorTitle="Erro ao acessar o manifesto"
    errorMessage="Cheque sua conexão e tente novamente."
    @retry="load"
  >
    <Story v-if="!loading" :data="data" :id="id" />
  </loader>
</template>
<script>
import Story from "../components/Story";
import { getTrackData } from "../scripts/data";
export default {
  data: () => ({
    data: { name: null, date: null, manifest: [] },
    error: false,
    loading: false,
  }),
  components: { Story },
  props: {
    id: String,
  },
  mounted() {
    window.scrollTo(0, 0);
    this.load();
  },
  methods: {
    load: function () {
      this.error = false;
      this.loading = true;
      getTrackData(
        this.id,
        (data) => {
          this.data = data;
          this.loading = false;
        },
        (error) => {
          this.error = true;
          console.error(error);
        }
      );
    },
    pageTitle: function () {
      return this.loading ? "carregando..." : this.data.title;
    },
  },
  metaInfo() {
    const title = this.pageTitle();
    return {
      titleTemplate: title + " | quem seremos?",
      meta: [
        {
          property: "og:title",
          content: title,
          vmid: "og:title",
        },
      ],
    };
  },
};
</script>