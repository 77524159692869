var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroller t5"},[_c('div',{staticClass:"scroller-body",style:({ height: _vm.itemHeight * 5 + 'px' })},[_c('ul',{ref:"ul",style:({
        webkitTransition:
          '-webkit-transform ' + _vm.transitionDuration / 1000 + 's ease-out',
        transition: 'transform ' + _vm.transitionDuration / 1000 + 's ease-out',
        webkitTransform:
          'translate3d(0px, ' + _vm.currentTranslatedY + 'px, 0px)',
        transform: 'translate3d(0px, ' + _vm.currentTranslatedY + 'px, 0px)',
      })},_vm._l((_vm.itemList),function(item){return _c('li',{key:item.id,class:[
          item.selected && !_vm.haveClicked && !_vm.isMouseDown ? 'selected' : '' ],style:({ height: _vm.itemHeight + 'px', lineHeight: _vm.itemHeight + 'px' }),attrs:{"data-val":item.value}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }