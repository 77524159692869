<template>
  <div class="footer d-flex flex-column justify-content-end bg-light py-2">
    <div class="row align-items-center">
      <div class="col-3 col-md-1 d-flex justify-content-end">
        <node-step
          class="btn btn-step"
          v-if="back"
          :step="back"
          @onEvent="onEvent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2rem"
            viewBox="0 0 416 416"
          >
            <path
              d="M0 208a15.9 15.9 0 005.6 12.1l224 192A16 16 0 00240 416h160a16 16 0 0010.4-28.2L200.6 208 410.4 28.2A16 16 0 00400 0H240a15.9 15.9 0 00-10.4 3.8l-224 192A16 16 0 000 208z"
            />
          </svg>
        </node-step>
      </div>
      <div class="col-auto d-flex flex-grow-1">
        <progress-bar :current="current" :total="total" class="flex-grow-1" />
      </div>
      <div class="col-3 col-md-1 d-flex justify-content-start">
        <node-step
          class="btn btn-step"
          v-if="next"
          :step="next"
          @onEvent="onEvent"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="2rem"
            viewBox="0 0 416 416"
          >
            <path
              d="M410.4 195.8l-224-192A15.9 15.9 0 00176 0H16A16 16 0 005.6 28.2L215.4 208 5.6 387.8A16 16 0 0016 416h160a16 16 0 0010.4-3.8l224-192c3.6-3.1 5.6-7.5 5.6-12.2s-2-9.1-5.6-12.2z"
            />
          </svg>
        </node-step>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    current: Number,
    total: Number,
    next: Object,
    back: Object,
  },
  methods: {
    onEvent(event) {
      this.$emit("onEvent", event);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
.footer {
  max-height: 100%;
  transition: all 1s;
  z-index: 100;
}
.btn-step {
  fill: $dark;
  transition: fill 200ms ease, box-shadow 1s ease;

  // padding: .5rem 2rem;
}
.btn-step:hover,
.btn-step:focus {
  fill: rgba($dark, 0.8);
}
.btn-step:active {
  fill: $primary;
}

.btn-step,
.btn-step:hover,
.btn-step:active,
.btn-step:focus {
  background: transparent;
  border: 0;
  border-radius: 0;
  box-shadow: 0 0 0 0.2rem $primary inset;
  outline: none;
}
.footer-title-transition-enter-active {
  animation: footer-transition 600ms;
}
.footer-title-transition-leave-active {
  animation: footer-transition 600ms reverse;
}
@keyframes footer-transition {
  from {
    opacity: 0;
    transform: translateX(20%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>