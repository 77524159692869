<template>
  <component
    :is="component.is"
    v-bind="component.options"
    :nodeId="nodeId"
    :isActive="isActive"
    :hasInvalid="hasInvalid"
    :manifest="manifest"
    @onEvent="onEvent"
    >{{ component.content
    }}<node-tree
      v-for="(node, index) in nodes"
      :key="`${node}-${index}`"
      :component="node.component"
      :nodes="node.nodes"
      :nodeId="nodeId"
      :isActive="isActive"
      :hasInvalid="hasInvalid"
      :manifest="manifest"
      @onEvent="onEvent"
    />
  </component>
</template>

<script>
export default {
  name: "node-tree",
  props: {
    component: Object,
    nodes: Array,
    nodeId: String,
    isActive: Boolean,
    hasInvalid: Boolean,
    manifest: Object,
  },
  methods: {
    onEvent(event) {
      this.$emit("onEvent", event);
    },
  },
};
</script>
