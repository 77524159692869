<template>
  <background image="00" class="full-container border-top-container">
    <logo />
    <div class="border-left-container">
      <div class="t5">A Covid-19 mudou nossa vida.</div>
      <div class="title mb-4">quem seremos?</div>
      <div class="t6 mb-4">
        Nesse game, descubra seu manifesto pessoal da pandemia.
      </div>
      <div>
        <a
          href="https://www.instagram.com/quemseremos/"
          target="_blank"
          class="mr-3"
          ><font-awesome-icon :icon="['fab', 'instagram']" size="3x"
        /></a>
        <a href="https://twitter.com/quemseremos" target="_blank">
          <font-awesome-icon :icon="['fab', 'twitter']" size="3x"
        /></a>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-center">
      <b-button variant="quem-seremos" @click="start">
        <span class="btn-text">início</span>
      </b-button>
    </div>
  </background>
</template>
<script>
import Background from "./Background.vue";
export default {
  components: { Background },
  methods: {
    start() {
      this.$emit("change-state");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
a {
  color: $light;
}
a:hover,
a:focus,
a:active {
  color: $primary;
}
.body {
  font-size: 1.8rem;
}
@media only screen and (max-width: 768px) {
  .body {
    font-size: 1.1rem;
  }
}
.title {
  font-size: 5rem;
  text-transform: uppercase;
  font-weight: 100;
  line-height: normal;
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
}
.btn-text {
  font-size: 3rem;
}
@media only screen and (max-width: 768px) {
  .btn-text {
    font-size: 1.5rem;
  }
}
</style>