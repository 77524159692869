<template>
  <div class="scroller t5">
    <div class="scroller-body" :style="{ height: itemHeight * 5 + 'px' }">
      <ul
        :style="{
          webkitTransition:
            '-webkit-transform ' + transitionDuration / 1000 + 's ease-out',
          transition: 'transform ' + transitionDuration / 1000 + 's ease-out',
          webkitTransform:
            'translate3d(0px, ' + currentTranslatedY + 'px, 0px)',
          transform: 'translate3d(0px, ' + currentTranslatedY + 'px, 0px)',
        }"
        ref="ul"
      >
        <li
          v-for="item in itemList"
          :key="item.id"
          :data-val="item.value"
          :class="[
            item.selected && !haveClicked && !isMouseDown ? 'selected' : '',
          ]"
          :style="{ height: itemHeight + 'px', lineHeight: itemHeight + 'px' }"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Scroller",
  props: {
    itemList: Array,
    itemHeight: { type: Number, default: 40 },
  },
  data() {
    return {
      selectedIndex: 0,
      selectedValue: 1,
      startPosY: 0,
      currentPosY: 0,
      startTime: 0,
      endTime: 0,
      lastTime: new Date().getTime(),
      transitionDuration: 0,
      lastPosY: 0,
      lastV: 0,
      startTranslatedY: 0,
      currentTranslatedY: 0,
      totalHeight: 0,
      haveClicked: false,
      isMouseDown: false,
    };
  },
  created() {},
  mounted() {
    // let supportedTouch = false;
    this.initData();
    // if ("ontouchstart" in window) {
    //   supportedTouch = true;
    // }
    // if (supportedTouch) {
    this.bindTouchEvents();
    // } else {
    this.bindMouseEvents();
    // }
    this.bindClickEvent();
  },
  computed: {},
  methods: {
    initData() {
      if (this.itemList.length > 0) {
        this.itemList.forEach((item, index) => {
          if (item.selected === true) {
            this.selectedValue = item.value;
            this.selectedIndex = index;
            return false;
          }
          return true;
        });
        this.startTranslatedY =
          this.itemHeight * 2 - this.selectedIndex * this.itemHeight;
        this.currentTranslatedY = this.startTranslatedY;
      } else {
        this.itemList = [{ value: 1, name: "Please select..", selected: true }];
      }
      this.totalHeight = this.itemList.length * this.itemHeight;
    },
    next() {
      this.changeIndex(this.selectedIndex + 1);
    },
    previous() {
      this.changeIndex(this.selectedIndex - 1);
    },
    changeIndex(index) {
      const el = this.$refs.ul;
      var group = el.querySelectorAll("li");
      if (index < 0 || index == group.length - 1) return;
      var li = group[index];
      const itemPositionY = li.offsetTop;
      const currentTranslatedY = this.itemHeight * 2 - itemPositionY;
      this.transitionDuration = 0;
      this.currentTranslatedY = currentTranslatedY;
      this.itemList[this.selectedIndex].selected = false;
      this.selectedIndex = index;
      this.itemList[this.selectedIndex].selected = true;
      this.selectedValue = this.itemList[this.selectedIndex].value;
      this.haveClicked = false;
    },
    bindTouchEvents() {
      const el = this.$refs.ul;
      // bind events
      el.addEventListener(
        "touchstart",
        (e) => {
          this.startPosY = e.changedTouches[0].pageY;
          this.currentPosY = this.startPosY;
          this.startTime = new Date().getTime();
          this.startTranslatedY = this.currentTranslatedY;
          this.lastV = 0;
          // console.log('touchstart!');
        },
        false
      );
      el.addEventListener(
        "touchmove",
        (e) => {
          e.preventDefault(); // prevent default scrolling event when touch moving
          this.lastV =
            (e.changedTouches[0].pageY - this.lastPosY) /
            (new Date().getTime() - this.lastTime);
          this.currentPosY = e.changedTouches[0].pageY;
          this.currentTranslatedY =
            this.startTranslatedY + this.currentPosY - this.startPosY;
          this.lastPosY = this.currentPosY;
          this.lastTime = new Date().getTime();
        },
        false
      );
      el.addEventListener(
        "touchend",
        () => {
          this.endTime = new Date().getTime();
          if (
            Math.abs(this.currentPosY - this.startPosY) > 5 &&
            this.endTime - this.startTime > 50
          ) {
            const v = this.lastV;
            const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001;
            const t = Math.abs(v) / 0.001;
            let currentTranslatedY = this.currentTranslatedY;
            currentTranslatedY += s;
            const residue = currentTranslatedY % this.itemHeight;
            if (Math.abs(residue) >= this.itemHeight / 2) {
              if (residue < 0) {
                currentTranslatedY += (this.itemHeight + residue) * -1;
              } else {
                currentTranslatedY += this.itemHeight - residue;
              }
            } else {
              currentTranslatedY -= residue;
            }
            if (currentTranslatedY > this.itemHeight * 2) {
              currentTranslatedY = this.itemHeight * 2;
            } else if (
              currentTranslatedY <
              (this.totalHeight - this.itemHeight * 3) * -1
            ) {
              currentTranslatedY =
                (this.totalHeight - this.itemHeight * 3) * -1;
            }
            const selectedIndex = Math.abs(
              (currentTranslatedY - this.itemHeight * 2) / -this.itemHeight
            );
            this.transitionDuration = t;
            this.currentTranslatedY = currentTranslatedY;
            setTimeout(() => {
              this.itemList[this.selectedIndex].selected = false;
              this.selectedIndex = selectedIndex;
              this.itemList[this.selectedIndex].selected = true;
              this.selectedValue = this.itemList[this.selectedIndex].value;
              this.haveClicked = false;
            }, t);
          } else {
            this.haveClicked = true;
          }
          this.startPosY = 0;
          this.currentPosY = 0;
          this.startTranslatedY = 0;
          this.startTime = 0;
          this.endTime = 0;
          this.lastPosY = 0;
          this.lastV = 0;
        },
        false
      );
    },
    bindMouseEvents() {
      const el = this.$refs.ul;
      let mouseDown = null;
      let mouseMove = null;
      let mouseUp = null;
      let mouseLeave = null;
      let mouseWheel = null;
      mouseDown = (e) => {
        // mouse down event
        this.isMouseDown = true;
        this.startPosY = e.pageY;
        this.currentPosY = this.startPosY;
        this.startTime = new Date().getTime();
        this.startTranslatedY = this.currentTranslatedY;
        el.addEventListener("mousemove", mouseMove);
        el.addEventListener("mouseup", mouseUp);
        el.addEventListener("mouseleave", mouseLeave);
        // console.log('mouseDown!');
      };
      mouseMove = (e) => {
        // mouse move event
        if (this.isMouseDown) {
          e.preventDefault(); // prevent default selecting event when mouse moving
          this.lastV =
            (e.pageY - this.lastPosY) / (new Date().getTime() - this.lastTime);
          this.currentPosY = e.pageY;
          this.currentTranslatedY =
            this.startTranslatedY + this.currentPosY - this.startPosY;
          this.lastPosY = this.currentPosY;
          this.lastTime = new Date().getTime();
          this.haveClicked = false;
        }
      };
      mouseUp = () => {
        // mouse up event
        this.endTime = new Date().getTime();
        if (
          Math.abs(this.currentPosY - this.startPosY) > 5 &&
          this.endTime - this.startTime > 20
        ) {
          const v = this.lastV;
          const s = v > 0 ? (0.5 * v ** 2) / 0.001 : (-0.5 * v ** 2) / 0.001;
          const t = Math.abs(v) / 0.001;
          let currentTranslatedY = this.currentTranslatedY;
          currentTranslatedY += s;
          const residue = currentTranslatedY % this.itemHeight;
          if (Math.abs(residue) >= this.itemHeight / 2) {
            if (residue < 0) {
              currentTranslatedY += (this.itemHeight + residue) * -1;
            } else {
              currentTranslatedY += this.itemHeight - residue;
            }
          } else {
            currentTranslatedY -= residue;
          }
          if (currentTranslatedY > this.itemHeight * 2) {
            currentTranslatedY = this.itemHeight * 2;
          } else if (
            currentTranslatedY <
            (this.totalHeight - this.itemHeight * 3) * -1
          ) {
            currentTranslatedY = (this.totalHeight - this.itemHeight * 3) * -1;
          }
          const selectedIndex = Math.abs(
            (currentTranslatedY - this.itemHeight * 2) / -this.itemHeight
          );
          this.transitionDuration = t;
          this.currentTranslatedY = currentTranslatedY;
          setTimeout(() => {
            this.itemList[this.selectedIndex].selected = false;
            this.selectedIndex = selectedIndex;
            this.itemList[this.selectedIndex].selected = true;
            this.selectedValue = this.itemList[this.selectedIndex].value;
            this.haveClicked = false;
          }, t);
        } else {
          this.haveClicked = true;
        }
        this.startPosY = 0;
        this.currentPosY = 0;
        this.startTranslatedY = 0;
        this.startTime = 0;
        this.endTime = 0;
        this.lastPosY = 0;
        this.lastV = 0;
        this.isMouseDown = false;
        el.removeEventListener("mousemove", mouseMove);
        el.removeEventListener("mouseup", mouseUp);
        el.removeEventListener("mouseleave", mouseLeave);
        // console.log('mouseUp!');
      };
      mouseLeave = () => {
        // mouse leave event
        if (this.isMouseDown) {
          mouseUp();
          // console.log('mouseLeave!');
        }
      };
      mouseWheel = (e) => {
        // mouse wheel event
        this.startTranslatedY = this.currentTranslatedY;
        let currentTranslatedY = this.startTranslatedY + e.deltaY * 0.5;
        const residue = currentTranslatedY % this.itemHeight;
        if (Math.abs(residue) >= this.itemHeight / 2) {
          if (residue < 0) {
            currentTranslatedY += (this.itemHeight + residue) * -1;
          } else {
            currentTranslatedY += this.itemHeight - residue;
          }
        } else {
          currentTranslatedY -= residue;
        }
        if (currentTranslatedY > this.itemHeight * 2) {
          currentTranslatedY = this.itemHeight * 2;
        } else if (
          currentTranslatedY <
          (this.totalHeight - this.itemHeight * 3) * -1
        ) {
          currentTranslatedY = (this.totalHeight - this.itemHeight * 3) * -1;
        }
        this.transitionDuration = 0.2;
        this.currentTranslatedY = currentTranslatedY;
        const selectedIndex = Math.abs(
          (currentTranslatedY - this.itemHeight * 2) / -this.itemHeight
        );
        setTimeout(() => {
          this.itemList[this.selectedIndex].selected = false;
          this.selectedIndex = selectedIndex;
          this.itemList[this.selectedIndex].selected = true;
          this.selectedValue = this.itemList[this.selectedIndex].value;
        }, this.transitionDuration);
        this.startTranslatedY = 0;
      };
      // bind events
      el.addEventListener("mousedown", mouseDown);
      el.addEventListener("wheel", mouseWheel);
    },
    bindClickEvent() {
      const el = this.$refs.ul;
      el.querySelectorAll("li").forEach(($li, index) => {
        $li.addEventListener("click", () => {
          if (this.haveClicked) {
            const itemPositionY = $li.offsetTop;
            const currentTranslatedY = this.itemHeight * 2 - itemPositionY;
            this.transitionDuration = 0;
            this.currentTranslatedY = currentTranslatedY;
            this.itemList[this.selectedIndex].selected = false;
            this.selectedIndex = index;
            this.itemList[this.selectedIndex].selected = true;
            this.selectedValue = this.itemList[this.selectedIndex].value;
            this.haveClicked = false;
          }
        });
        return true;
      });
    },
  },
  watch: {
    itemList: () => {
      this.totalHeight = this.itemList.length * this.itemHeight;
    },
    selectedIndex: function (val) {
      this.$emit("change", val);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
.scroller {
  padding: 0.5em 0.25em;
  max-width: 100%;
  -webkit-box-flex: 1;
  -webkit-flex: 1 auto;
  -ms-flex: 1 auto;
  flex: 1 auto;
  -ms-touch-action: none;
  touch-action: none;
}
.scroller-body {
  // overflow: hidden;
  position: relative;
}
.scroller-highlight {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: rgba($dark, 0.4);
  border-bottom: 0.15rem solid $primary;
  display: block;
}
.scroller-body ul {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  transform: translate3d(0px, 0px, 0px);
}
.scroller-body li {
  cursor: move;
  display: block;
  padding: 0 5px;
  margin: 0;
  white-space: nowrap;
  width: 100%;
  position: relative;
  text-overflow: ellipsis;
  transition: all 600ms;
  color: rgba($light, 0.5);
  font-weight: 100;
  filter: blur(1px);
}
.scroller-body li.selected {
  // font-weight: bold;
  color: $light;
  filter: blur(0);
}
</style>