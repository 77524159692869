<template>
  <div
    class="progress-container"
    :style="{ backgroundImage: progressContainer }"
  >
    <div :style="{ width: magnitude * 100 + '%' }" class="progress-bar"></div>
  </div>
</template>
<script>
export default {
  props: ["current", "total"],
  computed: {
    magnitude: function () {
      return this.current / this.total;
    },
    progressContainer: function () {
      return (
        "linear-gradient(to right, #00000033 " +
        this.lerp(0.4, 0.9, this.magnitude) * 100 +
        "%,  #00000000 0%"
      );
    },
  },
  methods: {
    lerp: function (value1, value2, amount) {
      amount = amount < 0 ? 0 : amount;
      amount = amount > 1 ? 1 : amount;
      return value1 + (value2 - value1) * amount;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
.progress-container {
  position: relative;
  width: 100%;
  height: 0.4rem;
  background-size: 0.4rem 100%;
  background-repeat: repeat-x;
  transition: background-image 1s ease;
}
.progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: $primary;
  transition: width 400ms ease;
}
</style>