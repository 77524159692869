export let images = {}

export async function load() {
    images = {};
    const requireContext = require.context("../assets/images", true);

    requireContext.keys().forEach((key) => {
        var obj = requireContext(key);
        var id = key.slice(2, key.length - 4);
        images[id] = obj
        var my_image = new Image();
        my_image.src = images[id]
    });
    // console.log(images)
}