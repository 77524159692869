import Vue from "vue";
import Router from "vue-router";
import Main from "../views/Main"
import End from "../views/End"

Vue.use(Router);

const routes = [
    {
        path: "*",
        redirect: "/",
    },
    {
        path: "/",
        name: "main",
        component: Main,
    },
    {
        path: "/manifesto/:id",
        props: true,
        name: "end",
        component: End,
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: routes,
});

export default router;
