<template>
  <div id="track" key="track">
    <div class="track-content" ref="content">
      <transition-group name="screen-transition">
        <TrackNode
          v-for="node in nodes"
          :key="node.id"
          v-show="isActive(node.id)"
          :isActive="isActive(node.id)"
          :hasInvalid="hasInvalid"
          :trackNode="node"
          :manifest="manifest"
          @onEvent="onEvent"
        />
      </transition-group>
    </div>
    <track-footer
      class="track-footer"
      v-if="currentNode"
      :next="currentNode.next"
      :back="currentNode.back"
      :current="currentIndex"
      :total="total"
      @onEvent="onEvent"
    />
  </div>
</template>

<script>
import TrackFooter from "../components/TrackFooter.vue";
import TrackNode from "../components/TrackNode";
import { loadTrack, log, data, valid } from "../scripts/data";

export default {
  data: () => ({
    nodes: {},
    currentNode: null,
    hasInvalid: false,
    hasError: false,
    isLoading: false,
    errorTitle: "",
    errorMessage: "Por favor tente novamente.",
    manifest: null,
  }),
  computed: {
    currentIndex: function () {
      for (var i = 0; i < Object.keys(this.nodes).length; i++) {
        if (Object.keys(this.nodes)[i] == this.currentNode.id) return i;
      }
      return -1;
    },
    total: function () {
      return Object.keys(this.nodes).length;
    },
  },
  mounted() {
    
    this.loadData();
  },
  components: {
    TrackNode,
    TrackFooter,
  },
  methods: {
    loadData() {
      this.state = "load";
      this.hasError = false;
      this.isLoading = true;
      this.manifest = require("../assets/data/manifest/data");
      loadTrack((json) => {
        this.nodes = json.track;
        this.currentNode = this.nodes[json.entryPoint];
        this.isLoading = false;
      });
    },
    onEvent(event) {
      switch (event.type) {
        case "changeBackground":
          if (event.data) this.background = event.data;
          break;
        case "goToNode":
          this.$refs.content.scrollTop = 0;
          if (event["validate"] != true || this.validateStep()) {
            this.logStep(event);
            this.goToNode(event.linkTo);
          }
          break;
        case "endTrack":
          this.$refs.content.scrollTop = 0;
          if (event["validate"] != true || this.validateStep()) {
            this.logStep(event);
            this.endTrack();
          }
          break;
      }
    },
    goToNode(id) {
      if (this.nodes[id]) {
        this.currentNode = this.nodes[id];
      } else console.log(id + " não encontrado");
    },
    endTrack() {
      this.$emit("change-state");
    },
    isActive(id) {
      return this.currentNode && id == this.currentNode.id;
    },
    logStep(event) {
      let logEntry = {};

      logEntry["createdOn"] = new Date();
      logEntry["from"] = this.currentNode.id;

      if (this.hasData) {
        let dataEntry = {};
        for (var [dataKey, dataValue] of Object.entries(data)) {
          dataEntry[dataKey] = dataValue.value;
        }
        logEntry["data"] = dataEntry;
      }

      let eventEntry = {};
      for (var [eventKey, eventValue] of Object.entries(event)) {
        eventEntry[eventKey] = eventValue;
      }
      logEntry["event"] = eventEntry;

      log.push(logEntry);
    },
    validateStep() {
      this.hasInvalid = false;
      let stepValid = true;
      for (var data of Object.entries(valid[this.currentNode.id])) {
        stepValid &= data[1];
      }
      if (!stepValid) this.hasInvalid = true;
      return stepValid;
    },
  },
};
</script>
<style lang="scss" scoped>
#track {
  // position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #000;
}
.track-content {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
