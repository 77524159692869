<template>
  <Background :image="background">
    <node-tree
      v-for="(node, index) in trackNode.nodes"
      :key="`${node}-${index}`"
      :component="node.component"
      :nodes="node.nodes"
      :nodeId="trackNode.id"
      :isActive="isActive"
      :hasInvalid="hasInvalid"
      :manifest="manifest"
      @onEvent="onEvent"
    />
    <year />
    <logo />
  </Background>
</template>

<script>
import Background from "./Background";
import NodeTree from "./NodeTree";

export default {
  props: {
    trackNode: Object,
    isActive: Boolean,
    hasInvalid: Boolean,
    manifest: Object,
  },
  components: {
    NodeTree,
    Background,
  },
  data: () => ({
    background: null,
  }),
  mounted() {
    this.background = this.trackNode.id;
  },
  methods: {
    onEvent(event) {
      this.$emit("onEvent", event);
    },
  },
};
</script>
