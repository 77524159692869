<template>
  <div class="story" id="story" ref="story" v-if="data">
    <section
      v-for="(item, index) in data.manifest"
      :key="`item-${index}`"
      v-show="current == index"
      :id="`item-${index}`"
    >
      <background
        :image="item.image"
        class="full-container border-top-container"
      >
        <a href="https://quemseremos.folha.uol.com.br">
          <logo :style="{ cursor: 'pointer' }"
        /></a>
        <div v-html="item.message"></div>
        <div class="signature mt-3 text-right">
          <Share id="share" :data="data" @sharing="sharing" />
          <div v-if="data.name && index != 5">
            {{ data.name }}
          </div>
          <div class="date" v-if="index != 5">
            {{ formatDate(data.date.toDate()) }}
          </div>
        </div>
      </background>
    </section>
    <nav id="story-nav">
      <div v-for="(item, index) in data.manifest" :key="`nav-${index}`">
        <div></div>
      </div>
    </nav>
  </div>
</template>
<script>
import Hammer from "hammerjs";
import anime from "animejs/lib/anime.es.js";
import Background from "./Background.vue";
import Share from "./Share.vue";
export default {
  components: { Background, Share },
  props: { id: String, data: Object },
  data() {
    return {
      current: 0,
      duration: 10000,
      timeline: null,
    };
  },
  mounted() {
    this.timeline = anime.timeline({
      autoplay: true,
      duration: this.duration,
      easing: "linear",
    });

    this.data.manifest.forEach((item, index) => {
      this.timeline.add({
        targets: this.$refs.story.querySelectorAll("nav > div")[index]
          .children[0],
        width: "100%",
        changeBegin: () => {
          this.current = index;
        },
      });
    });

    let hammertime = new Hammer(this.$refs.story);

    hammertime.on("press", () => {
      this.timeline.pause();
    });

    hammertime.on("pressup", () => {
      this.timeline.play();
    });

    hammertime.on("tap", (e) => {
      if (
        e.target.tagName == "BUTTON" ||
        e.target.tagName == "svg" ||
        e.target.tagName == "A"
      )
        return;
      if (e.center.x > window.innerWidth / 2) {
        if (this.current < this.data.manifest.length - 1) {
          this.current += 1;

          this.timeline.pause();
          this.timeline.seek(this.current * this.duration);
          this.timeline.play();
        }
      } else {
        if (this.current > 0) {
          this.current -= 1;

          this.timeline.pause();
          this.timeline.seek(this.current * this.duration);
          this.timeline.play();
        }
      }
    });
  },
  methods: {
    sharing(isSharing) {
      if (isSharing) {
        this.timeline.pause();
      } else {
        this.timeline.play();
      }
    },
    formatDate: function (data) {
      var day = [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ][data.getDay()];
      var date = data.getDate();
      var month = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ][data.getMonth()];
      var year = data.getFullYear();

      return `${day}, ${date} de ${month} de ${year}`;
    },
  },
};
</script>
<style lang="scss" >
@import "../assets/styles/variables";

.story,
.story section {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
.story-body {
  font-size: 1.8rem;
}
@media only screen and (max-width: 768px) {
  .story-body {
    font-size: 1.1rem;
  }
}
.story-title {
  font-size: 3rem;
}
@media only screen and (max-width: 768px) {
  .story-title {
    font-size: 1.8rem;
  }
}
.story nav {
  box-sizing: border-box;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  height: 0.5em;
  padding: 0 1em;
  position: fixed;
  bottom: 2em;
  width: 100%;
}
.story nav > div {
  background: $light;
  height: 100%;
}
.story nav > div > div {
  background: $primary;
  height: 100%;
  width: 0%;
}
.story .signature {
  font-size: 1.4rem;
}
@media only screen and (max-width: 768px) {
  .story .signature {
    font-size: 1.1rem;
  }
}
.story .signature .date {
  font-size: 1.1rem;
}
@media only screen and (max-width: 768px) {
  .story .signature .date {
    font-size: 0.8rem;
  }
}
</style>