<template>
  <loader
    :loading="loading && script1Loaded && script2Loaded"
    :error="error"
    errorTitle="Erro ao carregar a página"
    @retry="init"
  >
    <div class="barra-folha" ref="header"></div>
    <div id="app">
      <router-view />
    </div>
  </loader>
</template>

<script>
import postscribe from "postscribe";
import { signInAnonymous } from "./scripts/data";
export default {
  name: "App",
  data: () => ({
    loading: true,
    error: false,
    script1Loaded: false,
    script2Loaded: false,
  }),
  components: {},
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.loading = true;
      this.error = false;
      signInAnonymous(
        () => {
          this.loadHeader();
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.error = true;
        }
      );
    },
    loadHeader() {
      this.script1Loaded = true;
      this.script2Loaded = true;
      // postscribe(
      //   this.$refs.header,
      //   /* eslint-disable-next-line */
      //   `<script src="https://jsuol.com.br/barra/parceiro-async.js"><\/script>`
      // );
      postscribe(
        this.$refs.header,
        /* eslint-disable-next-line */
        `<script charset="windows-1252" src="https://www1.folha.uol.com.br/folha/furniture/navbar/search.js"><\/script>`
      );
      postscribe(
        this.$refs.header,
        /* eslint-disable-next-line */
        `<script>var _sf_async_config = _sf_async_config || {}; /** CONFIGURATION START **/ _sf_async_config.uid = 50059; _sf_async_config.domain = 'folha.com.br'; _sf_async_config.topStorageDomain = 'uol.com.br'; _sf_async_config.flickerControl = false; _sf_async_config.useCanonical = true; /** CONFIGURATION END **/ var _sf_startpt = (new Date()).getTime();<\/script>`
      );
      postscribe(
        this.$refs.header,
        /* eslint-disable-next-line */
        `<script src="//static.chartbeat.com/js/chartbeat_mab.js"><\/script>`
      );
      postscribe(
        this.$refs.header,
        /* eslint-disable-next-line */
        `<script>_sf_async_config.sections = 'Quemseremos'; _sf_async_config.authors = 'Quemseremos'; /** CONFIGURATION END **/ (function(){ function loadChartbeat() { window._sf_endpt=(new Date()).getTime(); var e = document.createElement('script'); e.setAttribute('language', 'javascript'); e.setAttribute('type', 'text/javascript'); e.setAttribute('src', '//static.chartbeat.com/js/chartbeat.js'); document.body.appendChild(e); } var oldonload = window.onload; window.onload = (typeof window.onload != 'function') ? loadChartbeat : function() { oldonload(); loadChartbeat(); }; })();<\/script>`
      );
      postscribe(
        this.$refs.header,
        /* eslint-disable-next-line */
        `<script type="text/javascript">(function (u, o, l, t, m) {m = u['universal_variable'] = u['universal_variable'] || {};m['dfp'] = m['dfp'] || {};u['uolads'] = u['uolads'] || [];var scr = o.createElement(l);scr.src = '//tm.jsuol.com.br/uoltm.js?id=' + t;scr.async = true;var el = o.getElementsByTagName(l)[0];el.parentNode.insertBefore(scr, el);})(window, document, 'script', 'ucg6dx');<\/script>​`
      );
    },
  },
};
</script>

<style lang="scss">
@import "./assets/styles/variables";
@import "./assets/styles/bootstrap";
@import "./assets/styles/stylesheet";
</style>
