<template>
  <b-overlay class="overlay-fill" variant="dark" opacity="1" :show="loading">
    <slot></slot>
    <template v-slot:overlay>
      <div v-show="!error" class="loader"></div>
      <b-alert :show="error" class="text-center" variant="danger">
        <h5 class="text-primary text-uppercase text-center font-weight-bold">
          {{ errorTitle }}
        </h5>
        <p class="text-light text-center font-weight-bold">
          {{ errorMessage }}
        </p>
        <b-button variant="primary" class="mt-4 px-5" @click="retry">
          <span class="h5 text-uppercase font-weight-bold">{{
            retryButton
          }}</span>
        </b-button>
      </b-alert>
    </template>
  </b-overlay>
</template>
<script>
export default {
  name: "loader",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorTitle: {
      type: String,
      default: "Ocorreu um erro.",
    },
    errorMessage: {
      type: String,
      default: "Verifique sua conexão e tente novamente.",
    },
    retryButton: {
      type: String,
      default: "Tentar novamente",
    },
  },
  methods: {
    retry() {
      this.$emit("retry");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/variables";
.overlay-fill {
  height: 100%;
  width: 100%;
  transition: all 1s;
}
.loader {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: $primary;
  vertical-align: middle;
  background: currentcolor;
  position: relative;
  animation: loader 1s ease-in-out infinite;
  animation-delay: 0.4s;
  width: 0.25em;
  height: 0.5em;
  margin: 0 0.5em;
  &:after,
  &:before {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    animation: inherit;
  }
  &:before {
    right: 0.5em;
    animation-delay: 0.2s;
  }
  &:after {
    left: 0.5em;
    animation-delay: 0.6s;
  }
}

@keyframes loader {
  0%,
  100% {
    box-shadow: 0 0 0 currentcolor, 0 0 0 currentcolor;
  }
  50% {
    box-shadow: 0 -0.25em 0 currentcolor, 0 0.25em 0 currentcolor;
  }
}
</style>