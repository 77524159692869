import firebase from "firebase/app";
import "firebase/auth"
import "firebase/firestore"
import "firebase/analytics"
import store from "../store/index";

const firebaseConfig = {
  apiKey: "AIzaSyAT5F-6Vg0EMfR7Z_thJtEdpJcWTjYi81I",
  authDomain: "quem-seremos.firebaseapp.com",
  databaseURL: "https://quem-seremos.firebaseio.com",
  projectId: "quem-seremos",
  storageBucket: "quem-seremos.appspot.com",
  messagingSenderId: "760476365472",
  appId: "1:760476365472:web:1cd0f5ed47fcc7c359cfc5"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();

auth.onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
});

const db = firebase.firestore();

db.settings({
  experimentalForceLongPolling: true
});

export { auth, db };
