<template>
  <div class="d-flex justify-content-center">
    <div class="scroll-picker d-flex align-items-center px-3">
      <div class="mr-1 t5 scroll-picker-title text-primary">
        {{ title }}
        <div class="control up">
          <b-button variant="control" @click="previous"
            ><b-icon-chevron-up
          /></b-button>
        </div>
        <div class="control down">
          <b-button variant="control" @click="next"
            ><b-icon-chevron-down
          /></b-button>
        </div>
      </div>
      <div>
        <scroller
          v-if="group.length > 0"
          :itemList="group"
          @change="onChange"
          ref="scroller"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Scroller from "@/components/Scroller";
import { data } from "../scripts/data";
export default {
  name: "scroll-picker",
  props: {
    id: String,
    nodeId: String,
    isActive: Boolean,
    manifest: Object,
  },
  components: { Scroller },
  data() {
    return {
      value: null,
      group: [],
      currentIndex: 0,
      title: "",
    };
  },
  mounted() {
    this.title = this.manifest[this.id].header.title;
    this.group = [];
    var choices = this.manifest[this.id].choice;
    Object.keys(choices).forEach((key) => {
      this.group.push({ id: key, name: choices[key].title, selected: false });
    });
    this.group[Math.round(this.group.length - 1) / 2].selected = true;
    this.onChange(0);
  },
  methods: {
    onChange(index) {
      this.currentIndex = index;
      this.value = this.group[index].id;
      this.emitValue();
    },
    emitValue() {
      var value = {};
      value[this.value] = true;
      data[this.id] = value;
    },
    next() {
      this.$refs.scroller.next();
    },
    previous() {
      this.$refs.scroller.previous();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
.scroll-picker {
  position: relative;
}
.scroll-picker::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 40px;
  background-color: rgba($dark, 0.4);
  border-bottom: 0.15rem solid $primary;
  display: block;
}
.scroll-picker-title {
  z-index: 1;
  position: relative;
}
.scroll-picker-title .control {
  position: absolute;
  right: 0;
}
.scroll-picker-title .control.up {
  top: -4.5rem;
}
.scroll-picker-title .control.down {
  bottom: -5rem;
}
.btn-control {
  color: $light;
  float: right;
  font-size: 2rem;
}
.btn-control:focus {
  box-shadow: none;
}
.btn-control:active {
  color: $primary;
}
</style>
