<template>
  <div>
    <b-button variant="share" @click="share"><b-icon-share-fill /></b-button>
    <b-modal
      hide-backdrop
      v-model="show"
      title="Compartilhe seu manifesto!"
      header-bg-variant="dark"
      header-text-variant="light"
      body-bg-variant="dark"
      body-text-variant="light"
      centered
      hide-footer
    >
      <div class="d-flex justify-content-center">
        <input
          type="text"
          class="form-control"
          :value="url"
          disabled
          ref="urlText"
        />
        <b-button variant="share" @click="copyToClipboard"
          ><b-icon-clipboard
        /></b-button>
      </div>
      <div class="d-flex justify-content-center mt-4">
        <a
          class="btn btn-share"
          v-for="(option, index) in shareOptions"
          :key="option.id"
          :aria-label="option.ariaLabel"
          :href="option.href"
          target="_blank"
          rel="noopener"
          :class="{ 'ml-3': index > 0 }"
        >
          <div>
            <font-awesome-icon :icon="option.icon" size="2x" />
          </div>
        </a>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: { data: Object },
  data: () => ({
    show: false,
    title: "Quem seremos depois da pandemia?",
    message: "Esse é o meu manifesto pessoal da quarentena!",
  }),
  watch: {
    show: function (value) {
      this.$emit("sharing", value);
    },
  },
  computed: {
    url: function () {
      return window.location.href;
    },
    shareOptions: function () {
      return [
        {
          id: "facebook",
          icon: ["fab", "facebook"],
          ariaLabel: "Compartilhe no Facebook",
          href:
            "https://facebook.com/sharer/sharer.php?u=" + encodeURI(this.url),
        },
        {
          id: "twitter",
          icon: ["fab", "twitter"],
          ariaLabel: "Compartilhe no Twitter",
          href:
            "https://twitter.com/intent/tweet?text=" +
            encodeURI(
              this.message +
                " " +
                this.url +
                "\nFaça também seu próprio manifesto\nhttps://quemseremos.folha.uol.com.br"
            ),
        },
        {
          id: "whatsapp",
          icon: ["fab", "whatsapp"],
          ariaLabel: "Compartilhe no Whatsapp",
          href:
            "https://wa.me/?text=" +
            encodeURI(
              "Faça o seu próprio manifesto da pandemia\nhttps://quemseremos.folha.uol.com.br\n" +
                this.message +
                "\n" +
                this.url
            ),
        },
        {
          id: "telegram",
          icon: ["fab", "telegram"],
          ariaLabel: "Compartilhe no Telegram",
          href:
            "https://t.me/share/url?url=" +
            encodeURI(this.url) +
            "&text=" +
            encodeURI(this.message),
        },
        {
          id: "email",
          icon: ["fas", "envelope"],
          ariaLabel: "Compartilhe no email",
          href:
            "mailto:?subject=" +
            encodeURI(this.title) +
            "&body=" +
            encodeURI(
              this.message +
                " " +
                this.url +
                "\nFaça também seu próprio manifesto\nhttps://quemseremos.folha.uol.com.br"
            ),
        },
      ];
    },
  },
  methods: {
    share: function () {
      if (navigator.share) {
        navigator
          .share({
            title: this.title,
            text: this.message + "\n" + this.url,
            url: "https://quemseremos.folha.uol.com.br",
          })
          .catch(console.error);
      } else {
        this.show = true;
      }
    },
    copyToClipboard: function () {
      var copyTextarea = this.$refs.urlText;
      copyTextarea.focus();
      copyTextarea.select();

      try {
        var successful = document.execCommand("copy");
        var msg = successful
          ? "URL copiada com sucesso!"
          : "erro ao copiar a URL";
        alert(msg);
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>
<style lang="scss" >
@import "../assets/styles/variables";
.btn-share {
  color: $light !important;
  background: transparent !important;
  border: 0 !important;
}
.btn-share:hover {
  color: $primary !important;
}
.btn-share:active,
.btn-share:focus {
  color: rgba($primary, 0.6) !important;
  background: transparent !important;
}
</style>