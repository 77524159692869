<template>
  <button ref="step" @click="onClick">
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: "node-step",
  props: {
    step: Object,
    isActive: Boolean
  },
  methods: {
    onClick() {
      //mount event
      let event = {};
      if (this.step["endTrack"]) {
        event["type"] = "endTrack";
      } else {
        event["type"] = "goToNode";
        event["linkTo"] = this.step.linkTo;
      }

      //if is has ID add it to the object for logging
      if (this.step["id"]) event["id"] = this.step.id;

      //flag if has step validation
      if (this.step["validate"]) event["validate"] = this.step.validate;

      //fire event
      this.$emit("onEvent", event);
    },
  },
};
</script>
<style lang="scss" scoped></style>
