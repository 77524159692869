<template>
  <loader :loading="isLoading" :error="hasError" @retry="end">
    <transition name="screen-transition">
      <Intro
        v-if="state == 'intro'"
        @change-state="
          () => {
            state = 'track';
          }
        "
      />
      <Track
        v-else-if="state == 'track'"
        @change-state="
          () => {
            state = 'outro';
          }
        "
      />
      <Outro v-else @change-state="end" /> </transition
  ></loader>
</template>
<script>
import Intro from "../components/Intro";
import Track from "../components/Track";
import Outro from "../components/Outro";
import Loader from "../components/Loader";
import { sendData } from "../scripts/data";
export default {
  components: { Intro, Track, Outro, Loader },
  data: () => ({
    state: "intro",
    isLoading: false,
    hasError: false,
  }),
  methods: {
    end() {
      this.isLoading = true;
      sendData(
        (id) => {
          this.isLoading = true;
          window.fbq("track", "conversao");
          this.$router.push("/manifesto/" + id);
        },
        (error) => {
          console.log(error);
          this.errorTitle = "Ocorreu um erro ao enviar os dados";
          this.hasError = true;
        }
      );
    },
  },
};
</script>