<template>
  <div
    class="background-image"
    :style="{
      backgroundImage: 'url(' + file + ')',
    }"
  >
    <slot></slot>
  </div>
</template>
<script>
import { images } from "../scripts/images";
export default {
  props: {
    image: String,
  },
  data: () => ({
    file: null,
  }),
  mounted() {
    this.file = images[this.image];
  },
  watch: {
    image: function (val) {
      if (images[val]) this.changeImage(images[val]);
    },
  },
  methods: {
    changeImage(newImage) {
      this.file = newImage;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../assets/styles/variables";
.background-image {
  display: flex;
  min-height: 100%;
  // height: 100%;
  overflow: auto;

  background-color: #000000aa;
  background-blend-mode: saturation;

  background-position: center;
  background-repeat: no-repeat;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  transition: background-image 400ms ease;
  -webkit-transition: background-image 400ms ease;
  -moz-transition: background-image 400ms ease;
  -o-transition: background-image 400ms ease;
}
</style>