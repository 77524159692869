import Vue from 'vue'

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import { library } from '@fortawesome/fontawesome-svg-core'
import { faInstagram, faTwitter, faFacebook, faWhatsapp, faTelegram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faInstagram)
library.add(faTwitter)
library.add(faFacebook)
library.add(faWhatsapp)
library.add(faTelegram)
library.add(faEnvelope)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import Loader from "../components/Loader";
Vue.component('loader', Loader)
import NodeStep from "../components/NodeStep";
Vue.component('node-step', NodeStep)
import CheckGroup from "../components/CheckGroup";
Vue.component('check-group', CheckGroup)
import ScrollPicker from "../components/ScrollPicker";
Vue.component('scroll-picker', ScrollPicker)
import ProgressBar from "../components/ProgressBar";
Vue.component('progress-bar', ProgressBar)
import Logo from "../components/Logo";
Vue.component('logo', Logo);
import Year from "../components/Year";
Vue.component('year', Year)

import { load } from "./images"
load();