import Vue from 'vue'
import router from "./routes/index";
import store from "./store/index";
import App from './App.vue'
import { auth } from "./scripts/firebaseConfig.js";
import "./scripts/global"

Vue.config.productionTip = false

let app;

auth.onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
  }
});